import http from "@/http"

let modulePath = "company/Login";

const post = function(path, data, config = {}) {
  return http.post(`${modulePath}/${path}`, data, config);
}

const get = function(path, config = {}) {
  return http.get(`${modulePath}/${path}`,config);
}

export default  {
  getOTP(dataObj) {
    return get("otp", {
      params: {
        email: dataObj.email.trim().toLowerCase(),
        companyCode: dataObj.companyCode
      }
    })
  },
  postLoginSubmit(email, code) {
    let data = new FormData();
    data.append("email", email);
    data.append("code", code)
    return post("login", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }
}