<template>
    <div>
      <el-icon style="cursor: pointer;font-size: 20px;top:5px;margin-right:10px;"
        @click="returnEmailPage"><arrow-left-bold />
      </el-icon>
      <span><b>{{ this.loginInfo.email }}</b></span>
    </div>
    <div :class="isInvalid(v$.password.$error, 'password')">
      <label>{{ $t("company.login.challenge.input.label") }}</label>
      <el-input show-password v-model="password" :placeholder="$t('company.login.challenge.input.placeholder')"
        @focus="v$.password.$reset()" :maxlength="6" @keypress="handleSubmit">
        <template #prefix>
          <el-icon class="el-input__icon">
            <!-- <font-awesome-icon icon="user-shield" /> -->
            <i class="bi bi-person-fill"></i>
          </el-icon>
        </template>
      </el-input>
      <el-alert style="margin-top:10px" v-if="v$.password.$error" :closable="false"
        :title="v$.password.$errors[0].$message" type="error" show-icon />
      <!--
    <el-button :disabled="resend.disabled" @click="btnResendOnClick">
      <span>{{ resend.text }}</span>
    </el-button>
    -->
      <div class="resend">
        <div v-show="resend.disabled">
          <span>{{ resend.text }}</span>
        </div>
        <div v-show="!resend.disabled" class="right">
          <el-link @click="btnResendOnClick" type="primary">{{
            $t("company.login.challenge.resend.text", { counter: this.resend.counter })
          }}</el-link>
        </div>
      </div>
    </div>
    <div class="submit">
      <el-button type="primary" @click="login">
        <span>{{ $t("company.login.challenge.button.text") }}</span>
      </el-button>
    </div>
</template>

<style>
.password>button.el-button {
  margin-top: 10px;
  width: 100%;
}

.resend {
  margin-top: 8px;
  font-size: 12px;
  padding: 0px 5px;
}

.password .el-link {
  font-size: 12px;
}

.resend>.right {
  text-align: right;
}

.submit>button.el-button {
  width: 100%;
}
</style>

<script>
import login from "../../http/apis/company/login";
import useVuelidate from '@vuelidate/core'
import * as validators from '@/libs/i18n-validators'
import { isInvalid, handleCompanyException, i18nPluralizationBase } from "@/libs/common"

export default {
  name: "CompanyLoginChallenge",
  data() {
    return {
      loginInfo: {
        email: null,
        companyCode: null
      },
      password: "",
      resend: {
        counter: 60,
        disabled: false,
        text: "",
      },
    };
  },
  setup() {
    return {
      v$: useVuelidate({
        $lazy: true,
        $autoDirty: false
      })
    }
  },
  validations() {
    return {
      password: {
        required: validators.required
      }
    }
  },
  emits: ["triggerLoading"],
  mounted() {
    this.getloginInfo();
    this.resendCD();
  },
  methods: {
    handleSubmit(event) {
      if (event.keyCode == 13) {
        this.login()
      }
    },
    returnEmailPage() {
      this.$router.push({ name: "CompanyLoginEmail" });
    },
    getloginInfo() {
      var params = this.$route.params;
      this.loginInfo.email = params.mail;
      this.loginInfo.companyCode = params.companyCode;
    },
    btnResendOnClick() {
      this.$emit("triggerLoading", true);
      login.getOTP(this.loginInfo).then(() => {
        this.resendCD();
      }).catch((err) => {
        handleCompanyException(err);
      }).finally(() => {
        this.$emit("triggerLoading", false)
      })
    },
    resendCD() {
      var text = this.resend.text;
      this.resend.disabled = true;
      this.counterRun(this.resend.counter, text);
    },
    async login() {
      let validateRes = await this.v$.password.$validate();
      if (!validateRes) return;
      this.$emit("triggerLoading", true);
      login.postLoginSubmit(this.loginInfo.email.toLowerCase(), this.password.trim().toUpperCase()).then(() => {
        this.$router.push({ name: "CompanyAgreement" });
      }).catch((err) => {
        handleCompanyException(err)
      }).finally(() => {
        this.$emit("triggerLoading", false)
      })
    },
    counterRun(counter, text) {
      if (counter >= 0) {
        this.resend.text = i18nPluralizationBase(
          "company.login.challenge.resend.counterText",
          { counter: counter-- }
        );
        setTimeout(() => this.counterRun(counter, text), 1000);
      } else {
        this.resend.text = text;
        this.resend.disabled = false;
      }
    },
    isInvalid(invalid, extraClass = "") {
      return isInvalid(invalid, extraClass)
    }
  },
};
</script>
